import React, { useState } from 'react'
import ClutchModal from './ClutchModal'
import ArrowIcon from '../../../static/ui/arrow-icon.inline.svg'

export default function ClutchModalButton({ className, children, arrow }) {
  const [activeModal, setActiveModal] = useState(null)

  return (
    <>
      <button className={className} onClick={() => setActiveModal('clutch-modal')}>
        {children}
        {arrow && <ArrowIcon />}
      </button>
      <ClutchModal activeModal={activeModal} setActiveModal={setActiveModal} />
    </>
  )
}
