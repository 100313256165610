import React from 'react'
import ReactModal from 'react-modal'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import styles from './clutch-modal.module.sass'

export default function ClutchModal({ activeModal, setActiveModal }) {
  function afterOpenModal() {
    disableBodyScroll(document.querySelector('.ReactModal__Overlay'))
    const script = document.querySelector('.clutch-script') || document.createElement('script')
    if (script.src) {
      window.CLUTCHCO.Init()
    }
    script.src = 'https://widget.clutch.co/static/js/widget.js'
    script.className = 'clutch-script'
    script.addEventListener('load', () => {
      window.CLUTCHCO.Init()
    })
    document.body.appendChild(script)
  }

  function closeModal() {
    enableBodyScroll(document.querySelector('.ReactModal__Overlay'))
    setActiveModal(null)
    window.CLUTCHCO.Destroy()
  }

  return (
    <ReactModal
      overlayClassName={styles.modalOverlay}
      className={styles.clutchModal}
      isOpen={activeModal === 'clutch-modal'}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      contentLabel="modal"
      ariaHideApp={false}
      id="clutch-modal"
    >
      <div className={styles.modalContent}>
        <div
          className="clutch-widget"
          data-url="https://widget.clutch.co"
          data-widget-type="4"
          data-height="auto"
          data-nofollow="true"
          data-expandifr="true"
          data-scale="100"
          data-clutchcompany-id="216537"
        ></div>
      </div>
      <button className={styles.closeButton} data-dismiss="modal" aria-label="Close" onClick={closeModal}>
        Close
      </button>
    </ReactModal>
  )
}
